import {lazy, useEffect, useState} from "react";
import {clone, isEmpty} from "ramda";
import {infiniteScrollFetch, recursivFetching} from "../../utils/api";
import {AppBar, Box, Button, debounce, Menu, MenuItem, Select, TextField, Toolbar, Typography} from "@mui/material";
import * as React from "react";

const HeaderAppBar = lazy(() => import('../headerAppBar'))
const DataTable = lazy(() => import("../table"))

const TableWrapper = ({config, urlToFetch, title}) => {

    const [dataToDisplay, setDataToDisplay] = useState([])
    const [time, setTime] = useState(null)
    const [timeToSub, setTimeToSub] = useState(null)
    const [numberOfRequest, setNumberOfRequest] = useState(null)
    const [numberOfItemWanted, setNumberOfItemWanted] = useState(null)
    const [isCalling, setIsCalling] = useState(false)
    const [toOrder, setToOrder] = useState(null)
    const [isOrdered, setIsOrdered] = useState(false)
    const [toFind, setToFind] = useState('')
    const [toSelect, setToSelect] = useState('')
    const [columns, setColumns] = useState([])
    const [selectSearchValue, setSelectSearchValue] = useState("all")
    const [metaData, setMetaData] = useState([])
    const [selected, setSelected] = useState([])
    const [isSearching, setIsSearching] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    let body = {
        "uri": config.CalculationView,
        "select": toSelect,
        "top": config.EntriesPerFetch,
        "skip": dataToDisplay.length
    }

    const orderColumn = (e) => {
        setToOrder(e.field)
        setIsOrdered(!isOrdered)
        setNumberOfRequest(0)
        setDataToDisplay([])
    }

    const resetOrder = () => {
        setIsOrdered(false)
        setToOrder(null)
        setNumberOfRequest(0)
        setDataToDisplay([])
    }

    const callApi = (bodyToSend) => {
        setIsLoading(true)
        let dataCopy = []
        let numberRequestCopy = 0
        let timeCopy = null
        let timeToSubCopy = null
        let needAuth = false
        let numberOfItemWantedCopy = numberOfItemWanted
        if(numberOfItemWanted === null){
            setNumberOfItemWanted(config.EntriesPerFetch * config.FetchIterationTotal)
            numberOfItemWantedCopy = config.EntriesPerFetch * config.FetchIterationTotal
        }
        if(metaData.length === 0) {
            bodyToSend.metadata = true
            recursivFetching(numberOfItemWantedCopy, 0, bodyToSend, config.EntriesPerFetch,
                dataCopy, setDataToDisplay, urlToFetch, timeToSubCopy, setTimeToSub,
                timeCopy, setTime, numberRequestCopy, setNumberOfRequest, needAuth, setMetaData, setIsLoading)
        } else {
            recursivFetching(numberOfItemWantedCopy, 0, bodyToSend, config.EntriesPerFetch,
                dataCopy, setDataToDisplay, urlToFetch, timeToSubCopy, setTimeToSub,
                timeCopy, setTime, numberRequestCopy, setNumberOfRequest, needAuth, setMetaData, setIsLoading)
        }
    }

    useEffect(() => {

        let columnsCopy = []
        let selectCopy= ''
        config.Columns.map((e) => {
            selectCopy += `${e.FieldName},`
            if(e.Orderable !== undefined && e.Orderable === 1) {
                columnsCopy.push({ field: e.FieldName, headerName: e.Label, type: e.Type, orderable: true})
            } else if (e.Type === 'action') {
                columnsCopy.push({ field: e.FieldName, headerName: e.Label, type: e.Type, cellAction: e.CellAction })
            } else {
                columnsCopy.push({ field: e.FieldName, headerName: e.Label, type: e.Type, orderable: false })
            }
        })
        selectCopy = selectCopy.slice(0, selectCopy.length - 1)
        setColumns(columnsCopy)
        setToSelect(selectCopy)

        if(isOrdered) {
            body.orderby = toOrder + ' asc'
            body.filter = toOrder + ' ne null and ' + toOrder + " ne '' "
        }

        // getData(body)
        if (toSelect.length > 0) {
            callApi(body)
        }

    }, [isOrdered, toSelect])

    window.onscroll = debounce(() => {

        // ajouter code pour que cela fonctionne avec recherche

        const body2 = {
            "uri": config.CalculationView,
            "select": toSelect,
            "top": config.EntriesPerFetch,
            "skip": dataToDisplay.length
        }

        if(isSearching) {
            if(toFind.length > 0) {
                if(selectSearchValue === "all") {
                    let newString = ""
                    config.Columns.map((e) => {
                        metaData.map((meta) => {
                            if(meta.name === e.FieldName) {
                                if(isNaN(Number(toFind))) {
                                    if(meta.quote) {
                                        newString += `${e.FieldName} eq '${toFind}' or `
                                    }
                                } else {
                                    if(meta.quote) {
                                        newString += `${e.FieldName} eq '${toFind}' or `
                                    } else {
                                        newString += `${e.FieldName} eq ${toFind} or `
                                    }
                                }
                            }
                        })
                    })
                    newString = newString.slice(0, newString.length -4)
                    body2.filter = newString
                } else {
                    metaData.map((meta) => {
                        if(meta.name === selectSearchValue) {
                            if(meta.quote) {
                                body2.filter = `${selectSearchValue} eq '${toFind}'`
                            } else {
                                body2.filter = `${selectSearchValue} eq ${toFind}`
                            }
                        }
                    })
                }
            }
        }

        if(isOrdered) {
            body2.orderby = toOrder + ' asc'
            body2.filter = toOrder + ' ne null and ' + toOrder + " ne '' "
        }

        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
            infiniteScrollFetch(config.EntriesPerFetch, body2, dataToDisplay, setDataToDisplay,
                urlToFetch, setIsCalling)
        }
    }, 500)

    let timeToShow = 0
    if(time !== null) {
        timeToShow = time - timeToSub
        timeToShow = timeToShow/1000
    }

    const searchAction = () => {

        let body3 = {
            "uri": config.CalculationView,
            "select": toSelect,
            "top": config.EntriesPerFetch,
            "skip": dataToDisplay.length
        }

        if(toFind.length > 0) {
            setIsSearching(true)
            if(selectSearchValue === "all") {
                let newString = ""
                config.Columns.map((e) => {
                    metaData.map((meta) => {
                        if(meta.name === e.FieldName) {
                            if(isNaN(Number(toFind))) {
                                if(meta.quote) {
                                    newString += `${e.FieldName} eq '${toFind}' or `
                                }
                            } else {
                                if(meta.quote) {
                                    newString += `${e.FieldName} eq '${toFind}' or `
                                } else {
                                    newString += `${e.FieldName} eq ${toFind} or `
                                }
                            }
                        }
                    })
                })
                newString = newString.slice(0, newString.length -4)
                body3.filter = newString
            } else {
                metaData.map((meta) => {
                    if(meta.name === selectSearchValue) {
                        if(meta.quote) {
                            body3.filter = `${selectSearchValue} eq '${toFind}'`
                        } else {
                            body3.filter = `${selectSearchValue} eq ${toFind}`
                        }
                    }
                })
            }
        } else {
            setIsSearching(false)
        }

        callApi(body3)
    }

    const handleSelect = (e) => {
        setSelectSearchValue(e.target.value)
    }

    const handleSearchBar  = (e) => {
        setToFind(e.target.value)
    }

    return (
        <>
            {toSelect.length > 0 ?
                <>
                    <HeaderAppBar title={title} config={config} toFind={toFind} handleSearchBar={handleSearchBar} isLoading={isLoading}
                                  handleSelect={handleSelect} selectSearchValue={selectSearchValue} searchAction={searchAction} selected={selected}/>
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 12, width: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                            <p>Nombre total de requetes : {numberOfRequest}</p>
                            <p>Nombre d'items récupérés : {dataToDisplay.length}</p>
                            <p>Temps total nécessaire pour les requetes : {time !== null ? timeToShow + 's' : 'en cours'}</p>
                            <p>Temps nécessaire pour une requete : {time !== null ? timeToShow/numberOfRequest + 's' : 'en cours'}</p>
                        </Box>
                        {isCalling &&
                            <Typography variant="h6" sx={{mb: 6, mt: 6}}>Chargement...</Typography>
                        }
                    </Box>
                    <DataTable rows={dataToDisplay} columns={columns} orderColumn={orderColumn} isOrdered={isOrdered} config={config}
                               resetOrder={resetOrder} selected={selected} setSelected={setSelected}/>
                </>
                :
                <p>Loading...</p>
            }

        </>
    )
}

export default TableWrapper