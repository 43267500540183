import * as React from "react";
import TableContainer from "../../components/tableWrapper";
import {useParams} from "react-router-dom";
import {useEffect} from "react";

const BoomLight = ({config}) => {


    let { entries, iteration ,type} = useParams()

    let urlToFetch = type === 'lourd' ?
        'https://automotive.encheres-vo.com/api/get-vehicle-entity':
        'https://automotive.encheres-vo.com/api/get-vehicle-json'


    useEffect(() => {
        if(entries !== undefined && iteration !== undefined) {
            config.EntriesPerFetch = Number(entries)
            config.FetchIterationTotal = Number(iteration)
        } else if (entries !== undefined && iteration === undefined) {
            config.EntriesPerFetch = Number(entries)
        }
    }, [entries, iteration])

    let title = config.DisplayName

    return (
        <>
            <TableContainer config={config} urlToFetch={urlToFetch} title={title} />
        </>
    )
}

export default BoomLight
