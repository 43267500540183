import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pages/home/Home";
import BoomLight from "./pages/boomLight";

function App() {

  const tableConfig = {
      "DisplayName": "VHStockPhysiqueLight",
      "CalculationView": "VHStockPhysiqueLight",
      "MenuName": "VHStockPhysiqueLight",
      "EntriesPerFetch": 100,
      "FetchIterationTotal": 2,
      "Filters": [
          {
              "FieldName": "CardNameVendeur",
              "Label": "Vendeur",
              "Type": "single",
              "Order": "ASC"
          },
          {
              "FieldName": "WhsName",
              "Label": "RS",
              "Type": "single",
              "Order": "ASC"
          },
          {
              "FieldName": "DateDebutVente",
              "Label": "Vente",
              "Type": "single"
          }
      ],
      "GlobalActions": [
          {
              "Label": "Créer dossier véhicule",
              "Type": "link",
              "Icon": "fad fa-cars mr-2",
              "Color": "primary",
              "Config": {
                  "Url": "vehicleFolder"
              }
          },
          {
              "Label": "Dupl.Folle Enchère",
              "Type": "api-call",
              "Icon": "fad fa-car mr-2",
              "Color": "primary",
              "Config": {
                  "Url": "duplicateFolleEnchere",
                  "UserConfirm": true,
                  "MessageConfirm": "Etes-vous sûr de vouloir continuer ?"
              }
          },
          {
              "Label": "Dupl.mandat",
              "Type": "api-call",
              "Icon": "fad fa-car mr-2",
              "Color": "primary",
              "Config": {
                  "Url": "duplicateMandat",
                  "UserConfirm": true,
                  "MessageConfirm": "Etes-vous sûr de vouloir continuer ?"
              }
          },
          {
              "Label": "Archiver VH",
              "Type": "api-call",
              "Icon": "fas fa-briefcase mr-2",
              "Color": "primary",
              "Config": {
                  "Url": "archiveVehicle",
                  "UserConfirm": true,
                  "MessageConfirm": "Etes-vous sûr de vouloir continuer ?"
              }
          },
          {
              "Label": "Créer modèles",
              "Type": "api-call",
              "Color": "primary",
              "Icon": "fad fa-car-side mr-2",
              "Config": {
                  "Url": "createModele",
                  "UserConfirm": true,
                  "MessageConfirm": "Etes-vous sûr de vouloir créer des modèles à partir des véhicules sélectionnés ?"
              }
          },
          {
              "Label": "Affecter à une vente",
              "Type": "render-view",
              "Color": "success",
              "Icon": "fad fa-gavel mr-2 --fa-secondary-opacity: 0.80",
              "Config": {
                  "Url": "vehicleSaleAssignment"
              }
          },
          {
              "Label": "Annuler de la vente",
              "Type": "api-call",
              "Icon": "fad fa-gavel mr-2",
              "Color": "success",
              "Config": {
                  "Url": "vehicleSaleRemove",
                  "UserConfirm": true,
                  "MessageConfirm": "Etes-vous sûr de vouloir continuer ?"
              }
          },
          {
              "Label": "Synchro Logiwin",
              "Type": "api-call",
              "Color": "warning",
              "Config": {
                  "Url": "updateLogiwin",
                  "UserConfirm": true,
                  "MessageConfirm": "Etes-vous sûr de vouloir synchroniser les données avec Logiwin ?"
              }
          },
          {
              "Label": "MAJ statut photo",
              "Type": "api-call",
              "Icon": "fad fa-camera mr-2",
              "Color": "warning",
              "Config": {
                  "Url": "updatePhotoStatus",
                  "UserConfirm": false
              }
          },
          {
              "Label": "Télécharger Macadam",
              "Icon": "fad fa-images mr-2",
              "Color": "warning",
              "Type": "api-call",
              "Config": {
                  "Url": "macadamizeVehicle"
              }
          },
          {
              "Label": "Télécharger photos Dekra",
              "Type": "api-call",
              "Icon": "fad fa-images mr-2",
              "Color": "warning",
              "Config": {
                  "Url": "downloadDekraPhotos",
                  "UserConfirm": false
              }
          },
          {
              "Label": "Impressions affichettes",
              "Type": "render-view",
              "Color": "secondary",
              "Icon": "fas fa-print mr-2",
              "Config": {
                  "Url": "printPoster"
              }
          },
          {
              "Label": "Imprimer pièces jointes",
              "Type": "render-view",
              "Color": "secondary",
              "Icon": "fas fa-print mr-2",
              "Config": {
                  "Url": "printPj"
              }
          },
          {
              "Label": "Impressions ALD",
              "Type": "render-view",
              "Color": "secondary",
              "Icon": "fas fa-print mr-2",
              "Config": {
                  "Url": "printALD"
              }
          },
          {
              "Label": "Génération dénonce",
              "Type": "render-view",
              "Color": "secondary",
              "Icon": "fas fa-print mr-2",
              "Config": {
                  "Url": "generateDenonce"
              }
          },
          {
              "Label": "Renseigner CT",
              "Type": "link",
              "Icon": "fad fa-chalkboard mr-2",
              "Color": "info",
              "Config": {
                  "Url": "update_technical_control"
              }
          },
          {
              "Label": "Renseigner CG",
              "Type": "link",
              "Icon": "fad fa-chalkboard mr-2",
              "Color": "info",
              "Config": {
                  "Url": "update-vehicle-carte-grise"
              }
          },
          {
              "Label": "Ecrire à",
              "Type": "render-view",
              "Color": "blue",
              "Icon": "fas fa-print mr-2",
              "Config": {
                  "Url": "sendMemo"
              }
          },
          {
              "Label": "Export excel",
              "Color": "blue",
              "Type": "export-csv"
          }
      ],
      "Columns": [
          {
              "Label": "Dossier",
              "FieldName": "DossierRefInt",
              "Type": "text"
          },
          {
              "Label": "VH",
              "Type": "action",
              "FieldName": "DocEntryVH",
              "CellAction": {
                  "FunctionName": "link",
                  "TargetEntity": "vehicleFolder",
                  "Icon": "edit",
                  "Params": {
                      "DocEntryVH": "docEntry",
                      "menuName": "VHStockPhysiqueLight"
                  }
              }
          },
          {
              "Label": "Création",
              "Type": "text",
              "FieldName": "DateCreation"
          },
          {
              "Label": "RS",
              "FieldName": "WhsName",
              "Type": "text"
          },
          {
              "Label": "Vend.",
              "FieldName": "CardNameVendeur",
              "Type": "text"
          },
          {
              "Label": "",
              "FieldName": "StatutLogiwinIcon",
              "Type": "icon"
          },
          {
              "Label": "Immat.",
              "FieldName": "Immatriculation",
              "Type": "text"
          },
          {
              "Label": "Genre",
              "FieldName": "Genre",
              "Type": "text"
          },
          {
              "Label": "Marque",
              "FieldName": "Marque",
              "Type": "text"
          },
          {
              "Label": "TypeComm",
              "FieldName": "TypeComm",
              "Type": "text"
          },
          {
              "Label": "Propriet./Debit",
              "FieldName": "ProprietaireDebiteur",
              "Type": "text"
          },
          {
              "Label": "Vente",
              "FieldName": "DateDebutVente",
              "Type": "text"
          },
          {
              "Label": "N°",
              "FieldName": "NumeroOrdre",
              "Type": "text"
          },
          {
              "Label": "N°Comp",
              "FieldName": "NumeroOrdreComplement",
              "Type": "text"
          },
          {
              "Label": "Réserve",
              "FieldName": "PrixReserve",
              "Type": "text"
          },
          {
              "Label": "Statut",
              "FieldName": "Statut",
              "Type": "text"
          },
          {
              "Label": "Date relevé",
              "FieldName": "DateReleveParc",
              "Type": "text"
          },
          {
              "Label": "Photos",
              "Type": "icon",
              "FieldName": "StatutPhotoGeneralIcon"
          },
          {
              "Label": "CT",
              "Type": "icon",
              "FieldName": "StatutControleTechniqueIcon"
          },
          {
              "Label": "ScCT",
              "Type": "icon",
              "FieldName": "StatutScanCTIcon"
          },
          {
              "Label": "ScSE",
              "Type": "icon",
              "FieldName": "StatutScanSEIcon"
          },
          {
              "Label": "Couleur",
              "Type": "text",
              "FieldName": "Couleur"
          },
          {
              "Label": "Nom FichImport",
              "Type": "text",
              "FieldName": "NomFichierImport"
          },
          {
              "Type": "text",
              "Hidden": true,
              "FieldName": "DocEntryVente"
          }
      ]
  }

    return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/:entries?/:iteration?/:type?" element={<BoomLight config={tableConfig} />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
